import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CitySelector from './CitySelector'
import styles from './ProfileAndCitySelectorHolder.module.css'
import ProfileSelector from './ProfileSelector'

const ProfileAndCitySelectorHolder = (props) => {
    const { isOpen, onClose } = props

    const [coordinates, setCoordinates] = useState(null);
    const [isCitySelectorActive, setIsCitySelectorActive] = useState(false)
    const [selectedCity, setSelectedCity] = useState('')
    const [forwardingUrl, setForwardingUrl] = useState('')
    const [isProfileSelectorActive, setIsProfileSelectorActive] = useState(false)
    const [selectedProfile, setSelectedProfile] = useState('')

    const citySelectorToggler = () => {
        setIsCitySelectorActive(false)
        document.getElementsByTagName("body")[0].classList.remove("no-scroll")
    }

    const selectCityHandler = (k) => {
        setSelectedCity(k)
        setIsCitySelectorActive(false)
        document.getElementsByTagName("body")[0].classList.remove("no-scroll")
    }
    const profileSelectorToggler = () => {
        setIsProfileSelectorActive(false)
        document.getElementsByTagName("body")[0].classList.remove("no-scroll")
    }

    const selectProfileHandler = (k) => {
        setSelectedProfile(k)
        setIsProfileSelectorActive(false)
        document.getElementsByTagName("body")[0].classList.remove("no-scroll")
    }

    window.onclick = function (event) {
        if (event.target.id === "mcpncsh") {
            onClose()
        }
    }

    const generateLandingUrl = () => {
        let fu = (selectedProfile?.label === 'Any') ? 'salon-jobs' : `${selectedProfile?.label || 'salon'}-jobs`;

        if (selectedProfile?.label === 'Any' && (!selectedCity || selectedCity.toLowerCase() === 'near-me')) {
            fu += '-near-me';
        } else if (selectedProfile?.label !== 'Any') {
            if (!selectedCity || selectedCity.toLowerCase() === 'near-me') {
                fu += '-near-me';
            } else if (selectedCity.toLowerCase() !== 'anywhere') {
                fu += `-in-${selectedCity}`;
            }
        }

        fu = fu.toLowerCase().trim()
            .replace(/[^a-z0-9\s-]/g, '')  // Remove special characters except spaces and hyphens
            .replace(/\s+/g, '-')          // Replace spaces with hyphens
            .replace(/-$/, '');            // Remove trailing hyphen if any

        setForwardingUrl(fu);
    };

    useEffect(() => {
        generateLandingUrl()
    }, [selectedCity, selectedProfile])

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#ffffff");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#ffffff";
            document.head.appendChild(meta);
        }
    }, []);


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                {isCitySelectorActive && <CitySelector
                    isOpen={isCitySelectorActive}
                    onClose={() => citySelectorToggler()}
                    selectCityHandler={selectCityHandler}
                    selectedCity={selectedCity}
                />}
                {isProfileSelectorActive && <ProfileSelector
                    isOpen={isProfileSelectorActive}
                    onClose={() => profileSelectorToggler()}
                    selectProfileHandler={selectProfileHandler}
                    selectedProfile={selectedProfile}
                />}
                <div className={styles.mainContainer} id="mcpncsh">
                    <div className={styles.mainContent}>
                        <div className={styles.formWrapper}>
                            <div className={styles.fieldContainer}>
                                <div className={styles.profileSearchWrapper}>
                                    <input placeholder="Please enter job profile..."
                                        autoFocus={true} onFocus={() => setIsProfileSelectorActive(true)}
                                        value={selectedProfile?.label}
                                    />
                                </div>
                                <div className={styles.citySearchWrapper}>
                                    <input placeholder="Please enter city..."
                                        value={selectedCity}
                                        onFocus={() => setIsCitySelectorActive(true)}
                                    />
                                </div>
                            </div>
                            <div className={styles.findJobBtn}>
                                <Link to={`/${forwardingUrl}`}>
                                    Find Jobs
                                </Link>
                            </div>
                            <div onClick={() => onClose()} className={styles.closeWrapper}>
                                Close 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileAndCitySelectorHolder